import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";

import Lazyload from "./page/Lazyload";
const Home = React.lazy(() => import("./page/Home"));
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          index
          element={
            <React.Suspense fallback={<Lazyload/>}>
              <Home />
            </React.Suspense>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
