import React from 'react'
const Lazyload = () => {
  return (
    <section className='lazyloading-wrapper'>
        <div className='container'>
            <div className='row vh-100'>
                <figure className='col-12 d-flex flex-column justify-content-center align-items-center'>
                    <figcaption className='lazy-loading-bullets d-flex mt-5'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className='me-0'></div>
                    </figcaption>
                </figure>
            </div>
        </div>
    </section>
  )
}

export default Lazyload